/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';



// body{
//   font-family: var(--ion-font-family);
//   font-family: var(--ion-font-family-ub);
//   font-family: var(--ion-font-family-mon);
// }

h1,h2,h3,h4,h5,h6{
  font-family: var(--ion-font-family);
}
p{
  font-family: var(--ion-font-family-ub);
}
ion-title{
  font-family: var(--ion-font-family);
}
ion-text{
  font-family: var(--ion-font-family-mon);
}
ion-card-subtitle{
  font-family: var(--ion-font-family);
}

// searchbar here to start
.homepage-search-bar .searchbar-input{
  background: #EAEAEA !important;
  border-radius: 25px !important;
  box-shadow: none;
}
.homepage-search-bar .sc-ion-searchbar-md-h{
  // margin-top: 20px;
}
.searchbar-search-icon{
  color: #000 !important;
}

.sear-fixed .searchbar-input{
  background: #EAEAEA !important;
  border-radius: 25px !important;
  box-shadow: none;
}

.sear-fixed {
  z-index: 111;
  background: #fff;
}

// menu-icon-grid
.menu-icon-grid .menu-title{
font-size: 14px;
}

// order-prescription-sec
.order-prescription-sec ion-card-subtitle{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.order-prescription-sec ion-card-content {
  color: #4A4A4A;  padding-left:10px;
  ion-label{
    color: #DA0001;
    font-weight: 500 !important;
    font-size: 15px !important;
    display: block;
  }
}
.order-prescription-sec ion-card-header{
  padding-bottom: 5px;
  padding-left:10px;
}
.order-prescription-sec ion-card{
  box-shadow: none; border-radius: 0;
  padding: 10px 0;
}

ion-button{
  letter-spacing: 0px;
}

// shop-category-sec here to start
// .shop-category-sec ion-button{
//   width:100%;
//   max-width:220px;
//   margin: 0px auto;
// }

.shop-category-sec ion-card-subtitle{
color: #1B1B1B;
font-weight: 600;
font-size: 13px;
line-height: 14px;
text-align: center;
}
.shop-category-sec ion-card-header{
padding-top: 5px;
padding-bottom: 0px;
padding-right:0px;
padding-left:0px;
text-align: center;
}

.shop-category-sec ion-text h2{
color: #000;
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 23px;
margin:0px;
padding:4px 10px;
}


.shop-category-sec ion-card img{
  border-radius: 8px;
  border: 1px solid #efefef;
  // box-shadow: 0 0 5px -3px black;
  height: 104px;
  // max-width:115px;
  width:100%;
  object-fit: cover;
}
.shop-category-sec ion-card{
box-shadow: unset;
}
.see-all-btn {
  // --border: 3px solid #000;
  // --border-color: #030080;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  // --border-width: 1px !important;
  text-transform: initial;
  // margin-top: 20px !important;
  // margin-bottom: 0px !important;
  font-family: var(--ion-font-family);
  // margin-top:0px !important;
  text-align: right;
  padding:2px 0px;
  text-transform: capitalize;
  height:30px;
  // margin:0px auto !important;
  --background: #28aae1;
    width: 70% !important;
    float: right;
  // --background: #ffffffa6;
  ion-icon{
    width:18px;
    height: 18px;
  }
}

// .see-all-btn::part(native){
//   padding-right:0px !important;
// }



.shop-category-sec .shop-btn{
  border: 1px solid #030080 !important;
  border-radius: 10px;
  box-shadow: none;
  width: 229px;
  height: 41px;
  margin: 0 auto;
}

.shop-category-sec .shop-btn{
color: #323232;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
text-transform: capitalize;
}

.shop-btn .button-native{
  --background-color: transparent !important;
}

.can-go-back {
  ion-menu-button:not(.home-menu-button) {
    display: none;
  }
}
.can-go-back {
  ion-back-button {
      display: block;
   }
}
ion-button{
  --border-radius:10px;
}

// ion-card-title here to start
ion-card-title{
  color: #1C3146;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 37px;
    // text-align: center;
}

.sign-in-text{
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #0073C7;
  font-family: var(--ion-font-family);
}
.mob-number-field .item-native{
border-bottom: 0px !important;
}
.mob-number-field{
  border: 2px solid #CFD8DD;
  border-radius: 6px;
}
.mob-number-field ion-label{
  color: #1C3146 !important;
  font-weight: 500;
  font-size: 14px !important;
  --placeholder-opacity: 1.5;
}

.mob-number-field ion-input{
  color: #1C3146 !important;
  font-weight: 500;
  font-size: 14px !important;
  --placeholder-opacity: 1.5;
}

.mob-number-field .sc-ion-label{
  font-weight: 500;
    font-size: 14px;
    color: #1C3146 !important;
}

.otp-verfict-btn{
  --box-shadow: unset !important;
  --border-radius:6px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  font-family: var(--ion-font-family);
}
// sign in to contuine here to start
.header-md::after{
  display: none;
}
ion-title{
    color: #1C3146;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
    // text-align: center;
    padding: 0;
    font-family: var(--ion-font-family);
 }
 ion-toolbar{
  padding-top: 5px !important;
  padding-bottom: 0;
 }
 .mb-20{
  margin-bottom: 20px;
 }

 .nointernet {
  /* --width: 244px; */
  --max-width: 100% !important;
  --max-height: 100% !important;
  --height: 100% !important;
}
.nointernet .alert-message.sc-ion-alert-md {
  max-height: 100%;
  font-size: 16px;
}

// otp login page
.otp-imgg{
  text-align: center;
  margin:0px 0px 25px 0px;
}

.otp-imgg img {
  width: 100%;
  max-width: 282px;
  height: auto;
}

input.native-input.sc-ion-input-md::placeholder {
  color: #1C3146 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.number-otp{
  color: #1C3146 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
// end otp login page

// register
.bor-input-line {
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.middle-head {
  text-align: center;
}

.register-itm input {
  height:45px !important;
  padding-left:5px !important;
  padding-right:5px !important;
}

.register-itm ion-select{
  height:45px !important;
  padding-left:5px !important;
  padding-right:5px !important;
}

.otp-very-form {
  margin: 30px 0px;
}
.otb-veri-box input {
  width: 50px !important;
  height: 50px !important;
  background: #FBFBFB;
  border: 0.5px solid #14224A !important;
  border-radius: 8px !important;
}

.otb-veri-box {
  padding-top: 10px;
  padding-bottom: 20px;
}

.otb-verify-butn {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.otp-veri {
  padding: 20px 0px;
}


span.star-icn {
  font-size: 22px;
  color: gray;
  font-weight: 600;
}

.accept_btns {
  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
}

.pad-space {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.home-tlt-heads {
  margin: 0px;
  padding: 0px 0px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  padding:4px 0px;
}

.card-normal-img img {
  width: 100%;
  // max-width: 337px;
  border-radius: 8px;
  height: 100%;
}

.new-arri ion-button {
  width: 100%;
  max-width: 220px;
  margin: 10px auto !important;
}

span.per-no {
  color: #db0000;
}

.featured-brandd ion-button {
  width: 100%;
  max-width: 220px;
  margin: 0px auto;
  --box-shadow:none !important;
  /* background: #fff !important; */
}



.feature-brnd-imag {
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.feature-brnd-imag ion-thumbnail {
  margin: 0px 0px;
}

.prod-new-arriv img {
  width: 100%;
  // max-width: 150px;
  height: 140px;
  object-fit: cover;
  // background: #F6F5FA;
  padding:8px;
}

.prod-new-arriv app-starrating p {
  margin: 0px !important;
}

.member-ship-img {
  text-align: center;
}

.mem-ber-ship ion-card-subtitle {
  text-align: center;
  /* color: #000; */
  color: #323232;
  font-size: 22px;
  font-weight: 700;
}



.star-rate h1 {
  color: #323232;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  padding: 8px 0px;
}

.explore-bttn {
  --background: #951A80;
  --border-radius: 18px;
  width: 100%;
  max-width: 220px;
  margin: 0px auto;
  font-weight: 600;
  color:#fff;
}

.pay-offe {
  text-align: center;
}

ion-buttons.cart-button {
   justify-content: center;
 }

.card-sze img {
  width: 100%;
  max-width: 100px !important;
  padding: 0px;
  object-fit: contain;
  height: 100%;
  /* max-width: 100px; */
}

.hed-tlt-sym {
  text-align: center;
  padding:0px;
}



.empty-cardtxt h2 {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.empty-cardtxt p {
  text-align: center;
  /* color: #000; */
}

ion-button.cart-button.card-remove {
  font-size: 10px;
  background: #F0F0F0;
  border-radius: 8px;
  font-weight: 600;
  padding:8px;
}

ion-button.cart-button.card-savebtn {
  background: #FEC107;
  color:#000;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  padding:8px;
}
.error-message {
  color: red;
  font-size: 12px;
  text-align: left;
}
app-category.app-cate ion-card {
  box-shadow: none !important;
  background: #DCF4F5 !important;
  border-radius: 8px;
}

// app-category.app-cate img {
//   width: 100%;
//   height: 100%;
//   height: 104px;
//   max-width: 104px;
//   object-fit: cover;
//   border-radius: 8px;
//   border: 1px solid #efefef;
// }

app-category.app-cate ion-card-header {
  padding: 5px 0px 10px 0px;
  text-align: center;

}

app-category.app-cate ion-card-subtitle{
  color:#000 !important;
  font-size:15px;
  font-weight:600;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 8px;
  
  // font-family: var(--ion-font-family-ub);
}

.app-cate img {
  width: 100%;
  // max-width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

app-starrating{
  display: flex;
  justify-content: center;
}

.order-prescription-sec ion-card-header{
  padding-left: 0px !important;
}
.order-prescription-sec ion-card-content {
  padding-left: 0px !important;
}
.order-prescription-sec ion-card{
  padding: 10px 12px;
}
.alert-message.sc-ion-alert-md {
  max-height: 266px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #1B1B1B;
}

.review-com{
  border: 1px solid #e1e1e1;
  height: 90px;
  border-radius: 8px;
  margin-bottom: 15px;
  }

  .review-com textarea.native-textarea.sc-ion-textarea-md{
    height: 90px;
    overflow: auto;
  }

// pop up order-summary
.select-interface-option {
    color: #000;
    font-weight: 700;
}

span.alert-button-inner.sc-ion-alert-md {
  font-weight: 600;
  justify-content: center ;
}
.alert-button-group.sc-ion-alert-md{
  // justify-content: center;
  justify-content: space-evenly;
}
// end

// attach precription

span.action-sheet-button-inner.sc-ion-action-sheet-md{
  color:#000;
  font-weight: 700;
}
.action-sheet-title {
  color: #132130 !important;
  font-size: 20px !important;
  font-weight: 600;
}
// end

.order-sum-textarea textarea.native-textarea.sc-ion-textarea-md {
  overflow-y: scroll;
  height: 90px;
  padding:0px 5px;
}

.accor-box-new{
  ion-accordion{
  ion-item{
      margin-bottom: 8px;
      border-radius:15px;
      ion-label{
      font-weight: 600;
      color: #1b1b1b !important;
     font-size: 16px;
      }
    
  }
  ion-icon.ion-accordion-toggle-icon.md.hydrated {
      color: #000;
      border-radius: 50%;
      // background: #28aae0;
      width:22px;
      height: 22px;
  }
}
}

.alert-button-cancel-new {
  background: #1c749a !important;
  color: #fff !important;
}

// main login pop-up
.mess-age-nm .alert-button-group {
  justify-content: space-evenly;
}

.mess-age-nm .alert-button {
  background: #1d4256 !important;
  color: #ffff;
  width: 33%;
  text-align: center;
}

.mess-age-nm span.alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}

.mess-age-nm .alert-button-group {
  justify-content: space-evenly;
  border-top: 1px solid #dfdfdf;
}

button.alert-button.ion-focusable.ion-activatable.alert-button-role-cancel.sc-ion-alert-md {
  background: #00215c;
  color: #fff;
  text-transform: capitalize;
}

button.alert-button.ion-focusable.ion-activatable.sc-ion-alert-md{
  background: #00215c;
  color: #fff;
  text-transform: capitalize;
  // width:60px;
}